import React from "react"
import { graphql } from "gatsby"

import * as styles from "../../styles/page/second.module.scss"
import * as dashboardStyles from "../../styles/page/dashboard.module.scss"
import * as chartStyles from "../../styles/components/chart.module.scss"

// 共通コンポーネント
import Meta from "../../components/layouts/meta"
import Layout from "../../components/layouts/default"
import BreadCrumb from "../../components/layouts/bread-crumb"
import Modal from "react-modal"

import SearchFilter from "../../components/parts/chart/search-filter"
import BarStacked from "../../components/parts/chart/bar-stacked"
import BarGrouped from "../../components/parts/chart/bar-grouped"
import BarStackGrouped from "../../components/parts/chart/bar-stack-grouped"
import MarketTrend from "../../components/parts/chart/market-trend"
import SankyModal from "../../components/parts/modal"

// markup
class IndexPage extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      modalOpen: false
    }

    this.searchInit = {
      filter1Init: 1,
      filter2Init: 0,
      filter3Init: 'market-trend',
      current: props.location.pathname
    }
    this.openModal = this.openModal.bind(this)
    this.closeModal = this.closeModal.bind(this)
  }

  openModal(e) {
    this.setState({modalOpen : true})
  }
  closeModal(e) {
    this.setState({modalOpen : false})
  }

  componentDidMount() {
    Modal.setAppElement('body');
  }

  render() {
    const markdownRemark = this.props.data.markdownRemark
    const { frontmatter } = markdownRemark
    const { title, description, slug } = frontmatter

    return (
      <Layout>
        <Meta 
          title={title}
          description={description}
          path={slug}
        />

        <BreadCrumb
          parentLink="/dashboard/"
          parentTitle="ダッシュボード"
          currentTitle={title}
        />

        <div className={styles.pageTitle}>
          <h1 className={styles.pageTitleHeading}>{title}</h1>
        </div>

        <SearchFilter {...this.searchInit} />
        
        <main className={styles.pageContents}>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <div className={chartStyles.chartTitle}>
                <h2>IEA NZEにおけるエネルギーバランス・フロー概要（2050年）</h2>
              </div>
              <div className={chartStyles.chartImageWrapper}>
                <button className={styles.modalLink} onClick={this.openModal}>
                  <img src="/images/chart/sanky-2.svg" alt="IEA NZEにおけるエネルギーバランス・フロー概要（2050年）" width="100%" height="auto" />
                </button>
                <SankyModal
                    open={this.state.modalOpen}
                    close={this.closeModal}
                    src={"/images/chart/sanky-2.svg"}
                    alt={"IEA NZEにおけるエネルギーバランス・フロー概要（2050年）"}
                    style={{width : '100%', height: 'auto'}}
                  />
              </div>
              <div className={chartStyles.chartNote}>
                <p className={chartStyles.chartSource}>出所）<a href="https://www.iea.org/reports/net-zero-by-2050" target="_blank" rel="noreferrer noopener">IEA "Net Zero by 2050"</a>（2021年5月）より作成</p>
                <p className={chartStyles.chartExplain}>IEAネットゼロシナリオにおける2050年の世界全体の一次エネルギー供給から最終エネルギー消費までのエネルギーの流れを示したもの。<br />
各項目の投入と産出の差分を損失として集計しており、原典に記載されている情報の制約により合計が一致しない項目もある。</p>
              </div>

            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStacked id="demandTrend" chartData="demandTrend" width="100%" height="420px" />
            </div>
          </div>
          
          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <BarGrouped id="hydrogenFutureEquipment" chartData="equipment" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
              <BarStackGrouped id="hydrogenFutureProduction" chartData="production" width="100%" height="420px" />
            </div>
          </div>

          <div className={dashboardStyles.chartArea}>
            <div className={dashboardStyles.chartAreaItem}>
              <MarketTrend id="marketTrend" chartData="hydrogen" width="100%" height="420px" />
            </div>

            <div className={dashboardStyles.chartAreaItem}>
            </div>
          </div>


        </main>
      </Layout>
    )
  }
}
export default IndexPage

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
        slug
      }
    }
  }
`