import React from "react"
import { StaticQuery, graphql } from "gatsby"
import * as am5 from "@amcharts/amcharts5/"
import * as am5xy from "@amcharts/amcharts5/xy"
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated"
import * as styles from "../../../styles/components/chart.module.scss"
import _ from "lodash"
import nl2br from "react-nl2br"
import canvasMemoryReset from "../../../utils/canvas-memory-reset"

// markup
class BarGrouped extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: props.chartData
    }
    
    this.filePath = "/csv/bar-grouped/" // CSVデータ格納先
  }

  // config setter
  setConfig(data) {
    this.config = data
  }

  // config getter
  getConfig() {
    return this.config
  }

  // チャートの設定
  async setChartData() {
    am5.addLicense(process.env.AM_CHARTS_LICENSE)
    
    let chartConfig = this.getConfig()
    let root = am5.Root.new(this.props.id)
    let xLabel = chartConfig.axis[this.state.chartData][0].title
    let xRealName = chartConfig.axis[this.state.chartData][0].realName

    root.setThemes([
      am5themes_Animated.new(root)
    ])
    this.root = root

    // 基本設定
    let chart = root.container.children.push(am5xy.XYChart.new(root, {
      panX: false,
      panY: false,
      wheelX: "none",
      wheelY: "none",
      layout: root.verticalLayout
    }))
    chart.children.unshift(am5.Label.new(root, {
      text: chartConfig.title[this.state.chartData],
      fontSize: "12px",
      centerX: am5.percent(50),
      x: am5.percent(50)
    }))

    let xRenderer = am5xy.AxisRendererX.new(root, {
      minGridDistance: 30,
      fontSize: "11px"
    })
    // X座標のラベルを指定
    xRenderer.labels.template.setAll({
      text: "{realName}",
      fontSize: "11px"
    });
    
    // X座標
    let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
        maxDeviation: 0,
        categoryField: xLabel,
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {
          labelText: "{realName}"
        })
      })
    );
    this.xAxis = xAxis

    // Y座標(左)
    let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
      maxDeviation: 0,
      min: chartConfig.axis[this.state.chartData][1].min,
      max: chartConfig.axis[this.state.chartData][1].max,
      numberFormat: "#,###",
      strictMinMax: true,
      calculateTotals: true,
      renderer: am5xy.AxisRendererY.new(root, {}),
      tooltip: am5.Tooltip.new(root, {})
    }));
    let rangeDataItem = yAxis.makeDataItem({
      value: chartConfig.axis[this.state.chartData][1].range
    })
    yAxis.createAxisRange(rangeDataItem)

    let yRenderer = yAxis.get('renderer')
    yRenderer.labels.template.setAll({
      fontSize: "11px"
    })

    yAxis.children.unshift(am5.Label.new(root, {
      rotation: -90,
      text: chartConfig.axis[this.state.chartData][1].title,
      y: am5.p50,
      centerX: am5.p50,
      fontSize: "11px",
    }))    
    this.yAxis = yAxis

    // シリーズの設定
    let series = chart.series.push(am5xy.ColumnSeries.new(root, {
      name: 'Series 1',
      xAxis: xAxis,
      yAxis: yAxis,
      valueYField: "value",
      sequencedInterpolation: true,
      categoryXField: xLabel,
      tooltip: am5.Tooltip.new(root, {
        labelText: `[#FFFFFF]{categoryX}年:{valueY}[/]`
      })
    }));
    series.columns.template.setAll({
      tooltipText: `[#FFFFFF]{categoryX}年:{valueY}[/]`,
      fill: this.barColor,
      stroke: this.barColor,
      templateField: "columnSettings"
    });

    series.columns.template.adapters.add("fill", (fill, target) => {
      return chartConfig.series[this.state.chartData][0].fill
    });
    series.columns.template.adapters.add("stroke", (stroke, target) => {
      return chartConfig.series[this.state.chartData][0].fill
    });

    // CSVファイルからデータの読み込み
    let dataSource = `${this.filePath}${this.state.chartData}.csv`
    let data = await am5.net.load(dataSource).then(function(result) {
      // CSVパースオプション
      let newData = []
      let data = am5.CSVParser.parse(result.response, {
        delimiter: ",",
        reverse: false,
        skipEmpty: true,
        useColumnNames: true
      });
      // 型変換処理
      let processor = am5.DataProcessor.new(root, {
        emptyAs: "",
        numericFields: ['value']
      });
      processor.processMany(data);
      // グルーピング用にCSVデータを加工
      data.forEach((obj, id) => {
        newData[id] = {}
        for (let k in obj) {
          if (k === 'provider') {
            newData[id][k] = obj[k]
            newData[id][xLabel] = `${obj['provider']} ${obj[xRealName]}`
            newData[id]['realName'] = obj[xRealName]
          } else {
            newData[id][k] = obj[k]
          }
        }
      })
      return newData
    })

    
    // Providerでグルーピング
    let byName = _.groupBy(data, 'provider')
    this.createProvider(byName)

    xAxis.data.setAll(data);
    series.data.setAll(data);
    series.appear();

    this.chart = chart
  }

  // Providerの指定
  createProvider(data) {
    let xAxis = this.xAxis
    Object.keys(data).forEach((key) => {
      let range = xAxis.makeDataItem({})
      xAxis.createAxisRange(range)
      
      range.set("category", data[key][0].category);
      range.set("endCategory", data[key][data[key].length - 1].category)
      
      let label = range.get("label")
      label.setAll({
        text: data[key][0].provider,
        dy: 20
      })
      
      let tick = range.get("tick");
      tick.setAll({ visible: true, strokeOpacity: 0.3, length: 40, location: 0 });
    
      //let grid = range.get("grid");
      //grid.setAll({ strokeOpacity: 1 });
    })
  }

  componentDidMount() {
    this.setChartData()
  }

  componentDidUpdate(prevState) {
    if (prevState.dataSourceUrl !== this.state.dataSourceUrl ) {
      this.root.dispose()
      this.setChartData()
    }
  }

  componentWillUnmount() {
    canvasMemoryReset(`#${this.props.id} canvas`)
    if (this.root) {
      this.root.dispose()
      this.root = null
    }
  }

  render() {
    return (
      <StaticQuery
        query={graphql`
          query allBarGroupedQuery {
            settings: allBarGroupedJson {
              edges {
                node {
                  axis {
                    equipment {
                      label
                      max
                      min
                      range
                      realName
                      title
                      type
                    }
                  }
                  series {
                    equipment {
                      fill
                      label
                      name
                      type
                    }
                  }
                  title {
                    equipment
                  }
                  head {
                    equipment
                  }
                  source {
                    equipment {
                      explain
                      sourceDate
                      sourceLink
                      sourceText
                    }
                  }
                }
              }
            }
          }
        `}
        render={data => (
          <>
            { this.setConfig(data.settings.edges[0].node) }
            <div className={styles.chartTitle}>
              <h2>{data.settings.edges[0].node.head[this.state.chartData]}</h2>
            </div>
            <div id={this.props.id} style={{ width: this.props.width, height: this.props.height }}></div>
            <div className={styles.chartNote}>
              <p className={styles.chartSource}>出所）<a href={data.settings.edges[0].node.source[this.state.chartData].sourceLink} target="_blank" rel="noreferrer noopener">{data.settings.edges[0].node.source[this.state.chartData].sourceText}</a>
              {data.settings.edges[0].node.source[this.state.chartData].sourceDate}
              </p>
              <p className={styles.chartExplain}>{nl2br(data.settings.edges[0].node.source[this.state.chartData].explain)}</p>
              <p><a className={styles.chartDataDownload} href={`${this.filePath}${this.state.chartData}.csv`}>データダウンロード</a></p>
            </div>
          </>
        )}
      />
    )
  }
}
export default BarGrouped
